import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import {
  List as ListIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

interface IKYCWorkflowRow {
  number: number;
  timeStamp: Date;
  workflowId: string;
  decision: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | Date },
  b: { [key in Key]: number | string | Date }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export interface IUserWorkflow {
  id: string;
  kycBypass?: boolean;
  kyc?: {
    workflows: Array<{
      jumioWorkflowId: string;
      updatedAt?: string;
      decision?: string;
    }>;
  };
}

const getRows = ({ kyc }: IUserWorkflow): IKYCWorkflowRow[] => {
  if (!kyc) {
    return [];
  }

  return kyc.workflows.map((item, index) => ({
    number: index + 1,
    timeStamp: item.updatedAt ? new Date(item.updatedAt) : new Date(),
    workflowId: item.jumioWorkflowId,
    decision: item.decision || 'N/A',
  }));
};

const KYCLabel = styled.span`
  margin-left: 5px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
  },
}));

export const ViewTransactionsButton = (props: { record: IUserWorkflow }) => {
  const { record: user } = props;
  const classes = useStyles();
  const [showTransactionDialog, setShowTransactionDialog] = useState(false);
  const [data] = useState<IKYCWorkflowRow[]>(getRows(user));
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof IKYCWorkflowRow>('timeStamp');

  const handleOpenClick = () => {
    setShowTransactionDialog(true);
  };

  const handleCloseClick = () => {
    setShowTransactionDialog(false);
  };

  const handleRequestSort = (property: keyof IKYCWorkflowRow) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Button
        title="View KYC workflow execution initiations"
        aria-label="View KYC workflow execution initiations"
        color="primary"
        disabled={!user.kyc}
        onClick={handleOpenClick}
      >
        <ListIcon />
        KYC
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showTransactionDialog}
        onClose={handleCloseClick}
        aria-label="Transactions"
      >
        <DialogTitle>KYC Workflow Execution Initiations</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table aria-label="KYC Transactions Table">
              <TableHead>
                <TableRow>
                  <TableCell
                    key="number"
                    align="right"
                    sortDirection={orderBy === 'number' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'number'}
                      direction={orderBy === 'number' ? order : 'asc'}
                      onClick={() => handleRequestSort('number')}
                    >
                      No.
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    key="timeStamp"
                    align="center"
                    sortDirection={orderBy === 'timeStamp' ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === 'timeStamp'}
                      direction={orderBy === 'timeStamp' ? order : 'desc'}
                      onClick={() => handleRequestSort('timeStamp')}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Workflow Id</TableCell>
                  <TableCell align="center">Decision</TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.sort(getComparator(order, orderBy)).map((row) => (
                  <TableRow key={row.workflowId} className={classes.root}>
                    <TableCell align="right">{row.number}</TableCell>
                    <TableCell align="center">
                      {moment(row.timeStamp).format('YYYY-MM-DD HH:mm')}
                    </TableCell>
                    <TableCell align="center">{row.workflowId}</TableCell>
                    <TableCell align="center">{row.decision}</TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        title="Show transaction in Jumio"
                        href={`https://customer-portal.lon.netverify.com/app/#/transactions/${row.workflowId}`}
                        target="blank_"
                        aria-label="Show transaction in Jumio"
                      >
                        <VisibilityIcon />
                        <KYCLabel>JUMIO</KYCLabel>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseClick}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
