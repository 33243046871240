import { InputLabel, Link } from '@material-ui/core';
import { LockOpen } from '@material-ui/icons';
import React, { useCallback } from 'react';
import {
  BooleanInput,
  Button,
  Confirm,
  DeleteButton,
  Edit,
  EditProps,
  Record,
  ReferenceArrayInput,
  ReferenceField,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  usePermissions,
} from 'react-admin';
import styled from 'styled-components';
import { enumToArray, User } from 'ultimate-league-common';
import { useBoolean } from 'usehooks-ts';
import { AddressField } from '~business/wallet/AddressField';
import { fetchApi, prepareSubmitRequestInit } from '~technical/api';

import { DeleteAccountButton } from './actions/DeleteAccount';
import { DisconnectEverywhereButton } from './actions/DisconnectEverywhere';

const UserActions = styled.div`
  display: flex;
  width: auto;

  > :not(:last-child) {
    margin-right: 24px;
  }
`;

const roleChoices = enumToArray(User.Role).map((role) => ({
  id: role,
  name: role,
}));

const StripeCustomerField = ({
  record,
  permissions,
  ...props
}: {
  record?: Record;
  permissions?: User.Role;
}) => {
  const stripeCustomerId = record?.payments?.stripeCustomerId;

  if (
    !stripeCustomerId ||
    (permissions && permissions !== User.Role.MODERATOR)
  ) {
    return null;
  }

  return (
    <>
      <InputLabel>Stripe customer id</InputLabel>
      <Link
        href={`https://dashboard.stripe.com/customers/${stripeCustomerId}`}
        target="_blank"
        rel="noreferrer"
      >
        <TextField
          record={record}
          source="payments.stripeCustomerId"
          {...props}
        />
      </Link>
    </>
  );
};

const PermissionedToolbar = ({
  permissions,
  ...props
}: { permissions: User.Role } & any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
    <DeleteButton disabled={permissions !== User.Role.MODERATOR} />
  </Toolbar>
);

const UserForm = ({ record, ...props }: SimpleFormProps) => {
  const {
    value: mustConfirmResetTwoFactor,
    setTrue: setMustConfirmResetTwoFactorTrue,
    setFalse: setMustConfirmResetTwoFactorFalse,
  } = useBoolean(false);
  const { permissions } = usePermissions();
  const notify = useNotify();

  const handleReset2Fa = useCallback(async () => {
    setMustConfirmResetTwoFactorFalse();

    try {
      await fetchApi(
        `/backoffice/user/reset-twoFactor`,
        prepareSubmitRequestInit(
          {
            userId: record.id,
          },
          'PATCH'
        )
      );

      notify('Two Factor has been reset', 'info');
    } catch (e: any) {
      notify(`Two Factor has not been reset (${e.message})`, 'error');
    }
  }, [record?.id, notify, setMustConfirmResetTwoFactorFalse]);

  return (
    <SimpleForm
      record={record}
      toolbar={<PermissionedToolbar permissions={permissions} />}
      {...props}
    >
      <TextInput
        disabled={permissions !== User.Role.MODERATOR}
        source="username"
      />
      <SelectInput
        disabled={permissions !== User.Role.MODERATOR}
        source="role"
        choices={roleChoices}
      />
      <BooleanInput
        source="kycBypass"
        label="Bypass KYC"
        disabled={permissions === User.Role.COMMUNITY}
      />
      <ReferenceField reference="wallet" source="wallet" label="" link="show">
        <AddressField source="address" />
      </ReferenceField>
      <StripeCustomerField permissions={permissions} />
      <ReferenceArrayInput source="cohorts" reference="usercohort">
        <SelectArrayInput
          disabled={permissions !== User.Role.MODERATOR}
          optionText="name"
        />
      </ReferenceArrayInput>
      <UserActions>
        {permissions !== User.Role.COMMUNITY && (
          <Button
            color="primary"
            variant="contained"
            label="Reset Two Factor"
            startIcon={<LockOpen />}
            onClick={setMustConfirmResetTwoFactorTrue}
            disabled={!record?.twoFaSecrets?.length}
          />
        )}
        <Confirm
          isOpen={mustConfirmResetTwoFactor}
          title="Reset Two Factor"
          content={`Are you sure you want to reset two-factor for ${record?.username}? Please confirm this action.`}
          onConfirm={handleReset2Fa}
          onClose={setMustConfirmResetTwoFactorFalse}
        />
        <DeleteAccountButton
          userId={record.id}
          userName={record.username}
          disabled={permissions !== User.Role.MODERATOR}
        />
        <DisconnectEverywhereButton
          userId={record.id}
          userName={record.username}
          disabled={
            permissions !== User.Role.MODERATOR &&
            permissions !== User.Role.COMMUNITY
          }
        />
      </UserActions>
    </SimpleForm>
  );
};

export const UserEdit = (props: EditProps) => (
  <Edit {...props}>
    {
      // @ts-ignore
      <UserForm />
    }
  </Edit>
);
